<!-- //科技管理 -->
<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="未处理" name="1"></el-tab-pane>
      <el-tab-pane label="已处理" name="2"></el-tab-pane>
    </el-tabs>
    <div class="bottom12">
      <el-row :gutter="20">
        <el-col :span="5" class="flex-row">
          <span class="span1">申请人</span>
          <el-input v-model="searchParams.applyName" style="width: 60%" placeholder="请输入内容"></el-input>
        </el-col>
        <el-col :span="5" class="flex-row">
          <span class="span1">申请人电话</span>
          <el-input v-model="searchParams.applyPhone" style="width: 60%" placeholder="请输入内容"></el-input>
        </el-col>
        <el-col :span="4" class="flex-row">
          <span class="span1">用途类型</span>
          <el-select style="flex: 1" clearable v-model="searchParams.useType" placeholder="请选择类型">
            <el-option v-for="item in useTypeArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="2" class="flex-row">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <!-- <el-table-column label="申请类型" width="250">
        <template slot-scope="scope">
          {{ getApplyType(scope.row.basicType) }}
        </template>
                                    </el-table-column> -->
      <el-table-column prop="applyName" label="申请人" width="250"> </el-table-column>
      <el-table-column prop="applyPhone" label="申请人电话" width="250"> </el-table-column>
      <el-table-column label="地址" width="250" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.county }}{{ scope.row.township }}{{ scope.row.village }}
        </template>
      </el-table-column>
      <el-table-column prop="applyTime" label="申请时间" width="200"> </el-table-column>
      <el-table-column label="服务" width="200">
        <template slot-scope="scope">
          {{ scope.row.serviceInfo.title }}
        </template>
      </el-table-column>
      <el-table-column prop="useTypeName" label="用途" width="200"> </el-table-column>
      <el-table-column prop="landName" label="地块" width="200"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button v-if="activeName == 1" type="text" size="small" @click="receiveApply(scope.row)">接受请求</el-button>
          <el-button type="text" size="small" @click="watchDetail(scope.row, 1)">服务详情</el-button>
          <el-button type="text" size="small" @click="watchDetail(scope.row, 2)">申请详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createServe type="technology" :dialogVisible.sync="dialogVisible" @updateList="getList"> </createServe>
    <applyDetail :id="applyId" :dialogVisible.sync="applyDetailVisible"></applyDetail>
    <serviceDetail :id="serviceId" :dialogVisible.sync="serviceDetailVisible"></serviceDetail>
    <createApply :applyData="applyData" :dialogVisible.sync="createApplyVisible" @updateList="getList"></createApply>
  </div>
</template>

<script>
import createServe from '../../../components/createServe.vue';
import applyDetail from './components/applyDetail.vue';
import serviceDetail from './components/serviceDetail.vue';
import createApply from './components/createApply.vue';
import { applyListUrl, applyApprove1 } from '@/api/api.js';
import dayjs from 'dayjs';
export default {
  components: {
    createServe,
    applyDetail,
    serviceDetail,
    createApply
  },
  data() {
    return {
      dialogVisible: false,
      applyDetailVisible: false,
      applyDetailData: null,
      serviceDetailVisible: false,
      serviceDetailData: null,
      createApplyVisible: false,
      applyData: null,
      applyId: '',
      serviceId: '',
      isEdit: false,
      title: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      contractName: '',
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '1',

      tableData: [],
      useTypeArr: [
        {
          id: 1,
          name: '巡田'
        },
        {
          id: 2,
          name: '植保'
        }
      ]
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getApplyType(type) {
      let msg = '';
      switch (type) {
        case 11:
          msg = '土地测评';
          break;
        case 12:
          msg = '实验室测士';
          break;
        case 13:
          msg = '科研咨询';
          break;
        case 14:
          msg = '农事咨询';
          break;
        default:
          break;
      }
      return msg;
    },
    watchDetail(item, type) {
      if (type == 1) {
        this.serviceDetailVisible = true;
        this.serviceDetailData = item;
        this.serviceId = item.serviceInfo.id;
      } else {
        this.applyDetailVisible = true;
        this.applyId = item.id;
      }
    },
    toDetail(item) {
      // this.$router.push({ name: 'detailService', query: { id: item.id } })
    },
    receiveApply(item) {
      this.applyData = item;
      this.createApplyVisible = true;
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    publish(item) {
      applyApprove1({ id: item.id }, '/api/operate/govserv/uav/apply/approve')
        .then((res) => {
          this.$message({
            message: '处理成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    handleClick(row) {
      this.searchParams = {};
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      applyListUrl(
        {
          state: this.activeName,
          basicType: 21,
          pageNum: this.pageInfo.currentPage,
          pageSize: this.pageInfo.pageSize,
          ...this.searchParams
        },
        '/api/operate/govserv/uav/apply/page'
      )
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
.bottom12 {
  margin-bottom: 18px;
}
</style>

<template>
  <div class="">
    <div class="device">
      <div v-for="item in tianqiList" :key="item.id" class="item">
        <img class="qx-img" :src="item.img" alt="" />
        <div class="content">
          <div>{{ item.name }}</div>
          <div class="value">{{ item.value }}{{ item.unit }}</div>
        </div>
      </div>
    </div>
    <div class="flex-row-wrj">
      <div class="view">
        <div class="title">土壤监测数据</div>
        <div class="turang">
          <div>
            <div
              v-for="item in areaList"
              :key="item.id"
              class="turang-area"
              :class="item.id == currentId ? 'turang-area-select' : 'turang-area-unselect'"
              @click="getSqDetailByLevel(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <img class="trsd" src="../../../assets/trsd.png" alt="" />
          <div style="margin-top: 24px">
            <div v-for="item in trsdList" :key="item.id" class="trsd-item">
              <div>土壤温度</div>
              <div style="color: #1890ff">{{ item.sqLevelDetailList[0].value }}</div>
              <div style="color: #1890ff">{{ item.sqLevelDetailList[0].unit }}</div>
              <div style="margin-left: 8px">土壤湿度</div>
              <div style="color: #fa8f00">{{ item.sqLevelDetailList[1].value }}</div>
              <div style="color: #fa8f00">{{ item.sqLevelDetailList[1].unit }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="view">
        <div class="title">无人机植保示意图</div>
        <img class="zbnt" src="../../../assets/zbnt.png" alt="" />
      </div>
    </div>
    <div class="bg-view tianqi">
      <div class="title"> 近期天气预报</div>
      <div class="device">
        <div v-for="item in weatherInfo" :key="item.id" style="background: white; height: 160px" class="item">
          <div style="align-items: center" class="content">
            <div class="title">{{ item.currentDate }}</div>
            <div class="title">{{ item.week }}</div>
            <div class="value">平均温度:{{ item.temperatureAvg }}℃</div>
            <div class="value"> 最高气温:{{ item.temperatureMax }}℃</div>
            <div class="value"> 最低气温:{{ item.temperatureMin }}℃</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';

import dayjs from 'dayjs';
export default {
  components: {
    // VueMarqueeSlider
  },
  data() {
    return {
      tianqiList: [],
      currentId: 18,
      trsdList: [],
      weatherInfo: [],
      areaList: [
        {
          name: '土壤监测1',
          id: 18
        },
        {
          name: '土壤监测2',
          id: 105
        },
        {
          name: '土壤监测3',
          id: 106
        },
        {
          name: '土壤监测4',
          id: 107
        },
        {
          name: '土壤监测5',
          id: 108
        }
      ]
    };
  },
  created() {},
  mounted() {
    this.getQixiang();
    this.getSqDetailByLevel(18);
    this.getWeatherInfo();
  },
  methods: {
    getQixiang() {
      postRequest('/api/iot/szsw/qxDetail', { deviceId: 17 })
        .then((res) => {
          res.forEach((e) => {
            if (e.sign == 'AA1') {
              e.img = require('../../../images/qx/kqwd.png');
            }
            if (e.sign == 'AF1') {
              e.img = require('../../../images/qx/jyl.png');
            }
            if (e.sign == 'e2') {
              e.img = require('../../../images/qx/trwd.png');
            }
            if (e.sign == 'e3') {
              e.img = require('../../../images/qx/trsd.png');
            }
            if (e.sign == 'AB1') {
              e.img = '';
            }
            if (e.sign == 'AL1') {
              e.img = require('../../../images/qx/gzqd.png');
            }
            if (e.sign == 'AD1') {
              e.img = require('../../../images/qx/fs.png');
            }
          });
          this.tianqiList = res.filter((e) => {
            if (e.img) {
              return e;
            }
          });
        })
        .catch((error) => {});
    },
    getSqDetailByLevel(id) {
      this.currentId = id;
      postRequest('/api/iot/sq/getSqDetailByLevel', { deviceId: id })
        .then((res) => {
          this.trsdList = res;
        })
        .catch((error) => {});
    },
    weekDay(time) {
      const weekNum = dayjs(time).day();
      let week = '';
      switch (weekNum) {
        case 0:
          week = '星期天';
          break;
        case 1:
          week = '星期一';
          break;
        case 2:
          week = '星期二';
          break;
        case 3:
          week = '星期三';
          break;
        case 4:
          week = '星期四';
          break;
        case 5:
          week = '星期五';
          break;
        case 6:
          week = '星期六';
          break;
      }
      return week;
    },
    getWeatherInfo() {
      postRequest('/api/iot/weather/findWeatherInfo', { cycle: 6 })
        .then((res) => {
          res.forEach((e, index) => {
            e.week = this.weekDay(e.weatherDate);
            if (index == 0) {
              e.currentDate = '今天';
            } else if (index == 1) {
              e.currentDate = '明天';
            } else if (index == 2) {
              e.currentDate = '后天';
            } else {
              e.currentDate = dayjs(e.weatherDate).format('MM-DD'); // 初始化日期
            }
          });

          this.weatherInfo = res;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.qx-img {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}
.turang-area {
  width: 160px;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  line-height: 36px;
  margin-bottom: 10px;
  border: 1px solid #dcdfe6;
  cursor: pointer;
}
.turang-area-select {
  background: #02b98f;
  color: #ffffff;
}
.turang-area-unselect {
  background: #dcdfe6 100%;
  color: #ffffff;
  color: #303133;
}
.turang {
  display: flex;
}
.flex-row-wrj {
  display: flex;
  justify-content: space-between;
  .view {
    background-color: #f8f8f9;
    width: 48%;
    height: 300px;
    padding: 12px;
    box-sizing: border-box;
  }
}
.title {
  font-weight: 500;
  color: #2c3542;
  font-size: 16px;
  margin-bottom: 6px;
}
.bg-view {
  background-color: #f8f8f9;
  width: 100%;
}
.tianqi {
  box-sizing: border-box;
  margin-top: 12px;
  padding: 12px;
}
.tips {
  background-color: #f8f8f9;
  width: 100%;
  height: 36px;
  line-height: 36px;
}
.device {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.item {
  background-color: #f8f8f9;
  flex: 1;
  flex-shrink: 0;
  margin: 20px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
    color: #2c3542;
    .value {
      color: #02b98f;
      margin-top: 6px;
    }
  }
}
.trsd {
  width: 150px;
  height: 200px;
  margin-left: 12px;
  margin-top: 24px;
}
.trsd-item {
  display: flex;
  height: 30px;
  margin-top: 16px;
  font-size: 10px;
  margin-left: 12px;
}
.zbnt {
  width: 100%;
  height: 269px;
}
</style>
